<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
        <!-- <li><a class="footer-content__list--item" href="https://agents.megabet.com.mx/">Agentes</a></li>
        <li><a class="footer-content__list--item" href="https://adm.megabet.com.mx/">Agentes 2.0</a></li> -->
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesSecond" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
        <li><a class="footer-content__list--item" href="https://agents.megabet.com.mx/">Agentes</a></li>
        <li><a class="footer-content__list--item" href="https://adm.megabet.com.mx/">Agentes 2.0</a></li>
      </ul>
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      return {
        routes: [
          {
            route      :  'sportsbook',
            name        : 'deportes en línea'

          },
          {
            route      :  'casino',
            name        : 'casino en línea'

          },
          {
            route      :  'racebook',
            name        : 'caballos en línea'

          }
        ],
        routesSecond: [
          {
            route      :  'house-rules',
            name        : 'reglas de la casa'
          },
          {
            route      :  'sports-rules',
            name        : 'reglas de deportes'
          }
        ]
      }
    },
  };
</script>
