<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="logo-container">
        <a href="/">
          <img class="header-content__img" src="@/assets/images/logo-megabet.png" alt="vegas247" />
        </a>
      </div>
      <div class="header-content__cta">
        <div class="header-buttons">
          <button class="btn-login secondary-button secondary-button__classic" type="submit" @click="openModalClassic">Classic</button>
          <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModalBetslip">Betslip</a>
        </div>
      </div>
    </div>
    <div class="header-content__nav-wrapper">
      <nav class="header-content__nav2">
        <div class="header-nav__container">
          <ul class="header-nav__menu">
            <div class="header-content__info">
              <ul class="header-content__info--list">
                <li class="header-content__info--list-item">
                  <router-link to="sportsbook"><img class="header-content__icon " src="@/assets/images/icons/sports.svg"
                      alt="Sports-icons">Deportes</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="casino"><img class="header-content__icon " src="@/assets/images/icons/casino.svg"
                      alt="Casino-icons">Casino</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="live-betting"><img class="header-content__icon " src="@/assets/images/icons/live.svg"
                      alt="Racebook-icons">Casino en vivo</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="racebook"><img class="header-content__icon " src="@/assets/images/icons/horses.svg"
                      alt="Racebook-icons">Caballos</router-link>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </nav>
    </div>
    <ModalComponentClassic ref="modal" />
    <ModalComponentBetslip ref="modalBetslip"/>
  </header>
</template>

<script>
import ModalComponentClassic from "./modalClassic.vue";
import ModalComponentBetslip from "./modalBetslip.vue";

export default {
  name: "HeaderComponent",
  components: {
    ModalComponentClassic,
    ModalComponentBetslip,
  },
  methods: {
    openModalBetslip() {
    this.$refs.modalBetslip.openModalBetslip();
    },
    openModalClassic() {
      this.$refs.modal.openModalClassic();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
  },
  setup() {
    const backendUrl = "megabet.com.mx";
    const DGS_SITEID = 1080;

    return {
      backendUrl,
      DGS_SITEID,
    };
  },
};
</script>
