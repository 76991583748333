<template>
  <div class="modal-backdropClassic" v-if="showModal">
    <div class="modal-content__heading">
      <h3>INGRESO A CLÁSICO</h3>
      <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
    <div class="modal-contentClassic">
      <!-- Contenido de tu modal aquí -->
      <!-- Mobile -->
      <form class="login__form form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" value="1037">
        <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`">
        <div class="login__form--item">
          <input class="login__form--input" type="text" placeholder="usuario" name="account" id="account">
        </div>
        <div class="login__form--item">
          <input class="login__form--input" type="password" placeholder="contraseña" name="password" id="password"/>
        </div>
        <!---->
        <div class="login__btns-login">
          <button class="login-button secondary-button secondary-button__betslip" type="submit">INGRESAR</button>
        </div>
      </form>
      <!-- Desktop -->
      <form class="login__form desktopModal" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
        <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
        <div class="login__form--item">
          <label class="login__form--label" for="username">Username</label>
          <input class="login__form--input" type="text" placeholder="usuario" name="account" id="account"/>
        </div>

        <div class="login__form--item">
          <label class="login__form--label" for="password">Password</label>
          <input class="login__form--input" type="password" placeholder="contraseña" name="password" id="password"/>
        </div>

        <div class="login__btns-login">
          <button type="submit" class="login-button">INGRESAR</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponentClassic',
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    openModalClassic() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  },
  setup() {
    const backendUrl = "megabet.com.mx";
    const DGS_SITEID = 1080;

    return {
      backendUrl,
      DGS_SITEID
    }
  },
};
</script>